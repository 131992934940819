/* Basic */

html, body {
  height: 100%;
}

#page-wrapper {
  min-height: 100%;
  position: relative;
}

body, input, select, textarea {
  font-size: 13pt;
  line-height: 1.75em;
  letter-spacing: 0.025em;
}

body {
  min-width: 1200px;
}

hr {
  margin: 2em 0 2em 0;
}

/* List */

ul {
  &.actions {
    margin: 3em 0 0 0;

    li {
      display: inline-block;
      margin: 0 0.75em 0 0.75em;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &.actions-centered {
    text-align: center;
  }
}

/* Wrappers */

.wrapper {

  .title {
    font-size: 0.9em;
    width: 25em;
    height: 3.25em;
    top: -3.25em;
    line-height: 3.25em;
    margin-bottom: -3.25em;
    margin-left: -12.5em;
    padding-top: 0.5em;
  }

}

/* Copyright */

#copyright {
  margin: 0;

  ul {
    padding: 0.75em 2em;
    font-size: 0.9em;

    li {
      display: inline-block;
      margin-left: 1em;
      padding-left: 1em;
      border-left: solid 1px #333;
      border-left-color: rgba(255, 255, 255, 0.05);

      &:first-child {
        border-left: 0;
        margin-left: 0;
        padding-left: 0;
      }
    }
  }
}

/* Header */

#header {
  position: relative;
  padding: 5em 0;
}

.homepage #header {
/*  padding: 14em 0; */
}

.no-sidebar #header {
/*  padding: 5em 0; */
}

#specs, #main {
  padding-bottom: 150px;
}

#finishes {
  padding-bottom: 100px;
}

/* Nav */

#nav {
  position: absolute;
  display: block;
  top: 4.0em;
  left: 0;
  width: 100%;
  text-align: center;

  > ul {
    display: inline-block;
    //padding: 0 1.5em 0 1.5em;

    > li {
      display: inline-block;
      text-align: center;
      padding: 0 1.5em 0 1.5em;

      > ul {
        /* display: none; */
        display: none;
      }
      > a, > span {
        display: block;
        color: #eee;
        color: rgba(255, 255, 255, 0.75);
        text-transform: uppercase;
        text-decoration: none;
        font-size: 0.7em;
        letter-spacing: 0.45em;
        height: 5em;
        line-height: 5em;
        -moz-transition: all .25s ease-in-out;
        -webkit-transition: all .25s ease-in-out;
        -o-transition: all .25s ease-in-out;
        -ms-transition: all .25s ease-in-out;
        transition: all .25s ease-in-out;
        outline: 0;
      }

      &:hover {
        > a {
          color: #fff;
        }
        > ul {
          margin-left: -60px;
          margin-top: -10px;
	  position: absolute;
          display: inline-block;
          z-index: 1000;
        }
      }

      &.active {
        > a, > span {
          color: #fff;
        }
      }
    }
  }
}


.dropotron {
  background: #222835 url('images/overlay.png');
  background-color: rgba(44, 50, 63, 0.925);
  padding: 1.25em 1em 1.25em 1em;
  border-radius: 0.35em;
  box-shadow: inset 0px 0px 1px 1px rgba(255, 255, 255, 0.25);
  min-width: 12em;
  text-align: left;
  margin-top: -1.25em;
  margin-left: -1px;

  &.level-0 {
    margin-top: -1px;
    margin-left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  a, span {
    @include vendor('transition', 'all .25s ease-in-out');
    display: block;
    color: #eee;
    color: rgba(255, 255, 255, 0.75);
    text-transform: uppercase;
    text-decoration: none;
    font-size: 0.7em;
    letter-spacing: 0.25em;
    border-top: solid 1px rgba(255, 255, 255, 0.15);
    line-height: 3em;
  }

  li {
    &:first-child {
      a, span {
        border-top: 0;
      }
    }

    &:hover {
      > a, > span {
        color: #fff;
      }
    }
  }
}

