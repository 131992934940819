/* Basic */

html, body {
  overflow-x: hidden;
}

body, input, select, textarea {
  line-height: 1.5em;
  font-size: 10.5pt;
  letter-spacing: 0;
}

h2, h3, h4, h5, h6 {
  font-size: 1.2em;
  letter-spacing: 0.05em;
  margin: 0 0 1em 0;
}

hr {
  margin: 1em 0 1em 0;
}

/* Section/Article */

section, article {
  clear: both;
  padding: 1em 0 1em 0 !important;
}

header {
  br {
    display: none;
  }

  &.style1 {
    padding: 0 0 1em 0;

    h2 {
      font-size: 1.5em;
      letter-spacing: 0.075em;
      line-height: 1.25em;
    }

    p {
      display: block;
      margin: 0;
    }
  }
}

/* Button */

input,
input[type="button"],
input[type="submit"],
input[type="reset"],
button,
.button {
  display: block;
  width: 100%;
  font-size: 1em;
  padding: 1em 0 1em 0;
  max-width: 30em;
  margin: 0 auto;
}

/* List */

ul {
  &.actions {
    margin: 2em 0 0 0;

    li {
      margin: 15px 0 0 0;
    }
  }
}

form ul.actions {
  margin: 1em 0 0 0;
}

/* Feature List */

.feature-list {
  section {
    border-top: solid 1px #eee;
  }

  > div > div:first-child > div:first-child > section {
    border-top: 0;
    padding-top: 0;
  }

  h3 {
    position: relative;
    padding: 4px 0 0 48px;
    line-height: 1.25em;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      line-height: 32px;
      font-size: 16px;
    }
  }

  p {
    margin: 0 0 0 48px;
  }
}

/* Box */

.box {
  &.post-excerpt {
    .image {
      &.left {
        position: relative;
        top: 0.25em;
        width: 25%;
        margin: 0;
      }
    }

    h3, p {
      margin-left: 32.5%;
    }
  }
}

/* Off-Canvas Navigation */

#page-wrapper {
  @include vendor('backface-visibility', 'hidden');
  @include vendor('transition', 'transform #{_duration(navPanel)} ease');
  padding-bottom: 1px;
}

#titleBar {
  @include vendor('backface-visibility', 'hidden');
  @include vendor('transition', 'transform #{_duration(navPanel)} ease');
  display: block;
  height: 44px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: _misc(z-index-base) + 1;
  background: none;

  .title {
    display: none;
  }

  .toggle {
    position: absolute;
    top: 0;
    left: 0;
    width: 60px;
    height: 44px;

    &:before {
      @include vendor('transition', 'all .15s ease-in-out');
      font-family: FontAwesome;
      text-decoration: none;
      font-style: normal;
      font-weight: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\f0c9';
      font-size: 14px;
      position: absolute;
      top: 6px;
      left: 6px;
      display: block;
      width: 54px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: rgba(255, 255, 255, 0.75);
      background-color: rgba(92, 95, 103, 0.5);
      border-radius: 0.25em;
    }
    &:active {
      &:before {
        background-color: rgba(92, 95, 103, 0.75);
      }
    }
  }
}

#navPanel {
  @include vendor('backface-visibility', 'hidden');
  @include vendor('transform', 'translateX(#{_size(navPanel) * -1})');
  @include vendor('transition', ('transform #{_duration(navPanel)} ease'));
  display: block;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: _size(navPanel);
  z-index: _misc(z-index-base) + 2;
  background: #242730 url('images/overlay.png');
  box-shadow: inset -3px 0px 4px 0px rgba(0, 0, 0, 0.1);

  .link {
    display: block;
    color: rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
    text-decoration: none;
    font-size: 0.85em;
    letter-spacing: 0.15em;
    text-decoration: none;
    height: 44px;
    line-height: 44px;
    border-top: solid 1px rgba(255, 255, 255, 0.05);
    margin: 0 15px 0 15px;

    &:first-child {
      border-top: 0;
    }
  }

  .indent-1 {
    display: inline-block;
    width: 1em;
  }

  .indent-2 {
    display: inline-block;
    width: 2em;
  }

  .indent-3 {
    display: inline-block;
    width: 3em;
  }

  .indent-4 {
    display: inline-block;
    width: 4em;
  }

  .indent-5 {
    display: inline-block;
    width: 5em;
  }

  .depth-0 {
    color: #fff;
  }
}

body {
  &.navPanel-visible {
    #page-wrapper {
      @include vendor('transform', 'translateX(#{_size(navPanel)})');
    }

    #titleBar {
      @include vendor('transform', 'translateX(#{_size(navPanel)})');
    }

    #navPanel {
      @include vendor('transform', 'translateX(0)');
    }
  }
}

/* Wrappers */

.wrapper {
  padding: 15px 15px 5em 15px;

  .title {
    font-size: 0.9em;
    width: 18em;
    height: 2.5em;
    top: -2.5em;
    line-height: 2.5em;
    margin-bottom: -2.5em;
    margin-left: -9em;
    padding-top: 0.5em;

    &:before,
    &:after {
      height: 15px;
      bottom: -15px;
      background-size: 100% 100%;
    }
  }
}

#header-wrapper {
  padding: 6em 2em 6em 2em;
}

#footer-wrapper {
  padding-top: 0em;
  .feature-list {
    section {
      border-top-color: #eee;
      border-top-color: rgba(255, 255, 255, 0.05);
    }
  }
}

/* Logo */

#logo {
  text-align: center;

  h1 {
    font-size: 1.5em;
    letter-spacing: 0.2em;
  }

  p {
    margin: 1.25em 0 0 0;
    display: block;
    letter-spacing: 0.2em;
    font-size: 0.9em;
  }
}

/* Nav */

#nav ul {
  display: none;
}

#nav_logo {
  display: block;
  text-align: center;
}

#header-wrapper { padding: 3em 0em 1em 0em; }

/* Intro */

#intro {
  text-align: center;
  padding: 1em 2em 1em 2em !important;
  margin: 0 auto;

  .style2 {
    font-size: 1.5em;
    letter-spacing: 0.05em;
    line-height: 1.25em;
    padding: 1.25em;
  }
}

/* Main */

#main {
  padding: 1em 0 0 0;
}

/* Content */

#content {
  padding: 0 0 2em 0;

  header {
    &.style1 {
      padding-bottom: 2.5em;
    }
    &.style2 {
      padding-bottom: 2.5em;
      text-align: left;
    }
  }
}

/* Footer */

#footer {
  hr {
    display: none;
  }
}

/* Copyright */

#copyright {
  padding: 2em 0 0 0;

  ul {
    padding: 1em 2em;
    width: 100%;

    li {
      display: block;
      margin: 0.5em 0 0 0;

      &:first-child {
        margin-top: 0;
      }
    }
  }
}
