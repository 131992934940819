@charset "UTF-8";
@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'font-awesome.min.css';
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,400italic,700,900');

/*
	Escape Velocity by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

	@import "libs/skel";

	@include skel-breakpoints((
		desktop: '(min-width: 737px)',
		tablet: '(min-width: 737px) and (max-width: 1200px)',
		mobile: '(max-width: 736px)'
	));

	@include skel-layout((
		reset: 'full',
		boxModel: 'border',
		grid: true,
		conditionals: true,
		containers: true,
		breakpoints: (
			desktop: (
				containers: 1200px,
				grid: ( gutters: (50px, 50px) )
			),
			tablet: (
				containers: 1000px,
				grid: ( gutters: (35px, 35px) )
			),
			mobile: (
				containers: (100%, true),
				grid: ( gutters: (10px, 10px) )
			)
		)
	));

/* Basic */

@-ms-viewport {
	width: device-width;
}


@import "base";

//@include breakpoint(desktop) {
@media screen and (min-width: 737px) {
	@import "desktop";
}


//@include breakpoint(tablet) {
@media screen and (min-width: 737px) and (max-width: 1200px) {
	@import "tablet";
}

//@include breakpoint(mobile) {
@media screen and (max-width: 736px) {
	@import "mobile";
}
