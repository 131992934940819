@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&display=swap');

#header-image {
  text-align: center;
}

.ps-caption b {
  color: inherit;
}

#specs, #main {
  padding-bottom: 4em;
}

.homepage #header-wrapper {
  background-size: contain;
  background-repeat: no-repeat;
}

.price{
}

.style1
{
  h1 {
    font-size: 4em;
    margin-top: .5em;
    margin-bottom: .5em;
    font-family: 'Cinzel', serif;
  }

}

body {
  background-color: #202020;
}

.content-page {
  h1 {
    font-size: 2em;
    margin-top: .5em;
    margin-bottom: .5em;
  }

  h2 {
    font-size: 1.5em;
    margin-top: .5em;
    margin-bottom: .5em;
  }

  h3, h4, strong {
  }

  ul {
    list-style: square;
    padding-left: 2em;
  }

  .price-list {
    a {
      text-decoration: none;
    }

    h2:hover {
      color: lightgray !important;
    }
  }

}

.image {
  &.list {
    border: 2px solid white;
  }
}

.popover-container {
  display: flex;
}

.popover-container li {
  list-style-type: none;
  position: relative;
  padding: 10px;
}

.popover-container .big-popup {
  position: absolute;
  top: 25px;
  left: 25px;
  display: none;
  z-index: 1;
  width: 500px;
  height: 500px;
  border: 2px solid slategrey;
}

