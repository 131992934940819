body, input, textarea, select {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400;
  color: #919499;
    background: url('images/overlay.png');
}

body {
  &.is-loading * {
    @include vendor('transition', 'none !important');
    @include vendor('animation', 'none !important');
  }
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 700;

  a {
    color: inherit;
    text-decoration: none;
  }
}

a {
  @include vendor('transition', 'color .25s ease-in-out');
  text-decoration: underline;
  color: #717479;

  &:hover {
    text-decoration: none;
  }
}

strong, b {
  font-weight: 700;
}

blockquote {
  border-left: solid 0.5em #ddd;
  padding: 1em 0 1em 2em;
  font-style: italic;
}

em, i {
  font-style: italic;
}

hr {
  border: 0;
  border-top: solid 1px #ddd;
}

sub {
  position: relative;
  top: 0.5em;
  font-size: 0.8em;
}

sup {
  position: relative;
  top: -0.5em;
  font-size: 0.8em;
}

.nobr {
  white-space: nowrap;
}

br.clear {
  clear: both;
}

p, ul, ol, dl, table, blockquote, form {
  margin-bottom: 2em;
}

p {
  /* font-size: 70%; */
  line-height: 1.5;
}

.post li {
  /* font-size: 70%; */
  line-height: 1.5;
}

/* Image */

.image {
  display: inline-block;

  img {
    display: block;
    width: 100%;
  }

  &.fit {
    display: block;
    width: 100%;
  }

  &.left {
    float: left;
    margin: 0 2em 2em 0;
  }

  &.centered {
    display: block;
    margin: 0 0 2em 0;

    img {
      margin: 0 auto;
      width: auto;
    }
  }

  &.featured {
    display: block;
    width: 100%;
    margin: 0 0 2em 0;
    border: 4px solid white;
  }
}

/* Wrappers */
.wrapper {
  position: relative;

  .title {
    position: absolute;
    top: 0;
    left: 50%;
    text-align: center;
    text-transform: uppercase;
    display: block;
    font-weight: 700;
    letter-spacing: 0.25em;

    &:before {
      content: '';
      position: absolute;
      bottom: -38px;
      left: -35px;
      width: 35px;
      height: 38px;
      background: url('images/shadow.png');
    }

    &:after {
      @include vendor('transform', 'scaleX(-1)');
      content: '';
      position: absolute;
      bottom: -38px;
      right: -35px;
      width: 35px;
      height: 38px;
      background: url('images/shadow.png');
    }
  }

  &.style1 {
    background: #e97770 url('images/overlay.png');
    color: #eee;
    color: rgba(255, 255, 255, 0.75);

    .title {
      background: #e97770 url('images/overlay.png');
      color: #fff;
    }

  }

  &.style2 {
    background: #fff;

    .title {
      background: #fff;
      color: #484d55;
    }
  }

  &.style3 {
    background: #f3f3f3 url('images/overlay.png');

    .title {
      background: #f3f3f3 url('images/overlay.png');
      color: #484d55;
    }

    .image {
      border: solid 10px #fff;
    }
  }

  &.style4 {
    background: #9c6f00
  }

  &.style5{
    background: #000;

    .title {
      background: #000;
      color: #929dad;
    }
  }

  &.style6{
  }
}

#header-wrapper {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('images/overlay.png');
  }
}

.homepage #header-wrapper {
/*  background: black url('images/HomePageHeader3.png') center center; */
}

.no-sidebar #header-wrapper {
  /* background: black; */
}


#footer-wrapper {
  bottom: 0;
  width: 100%;
  padding: 10px;
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
  background: url('images/overlay.png');
  background-color: #202020;
  font-size: 60%;
}


/* Copyright */

#copyright {
  text-align: center;

  a {
    color: inherit;

    &:hover {
      color: #fff;
    }
  }
}


#navPanel, #titleBar {
  display: none;
}

#my-form input {
  border: 1px solid #ddd;
  box-sizing: border-box;
}
