/* Basic */

body {
  min-width: 960px;
  font-size: 12pt;
  line-height: 1.5em;
  letter-spacing: 0.015em;
}

input, select, textarea {
  font-size: 12pt;
  line-height: 1.5em;
  letter-spacing: 0.015em;
}

/* Wrappers */

.wrapper {
}

#intro-wrapper {
}

#footer-wrapper {
  hr {
    margin: 3em 0 3em 0;
  }
}

/* Header */

#header {
}


/* Logo */

#logo {
  margin-top: -0.5em !important;

  h1 {
    font-size: 1.75em;
  }
}

/* Intro */

#intro {
  > .style1 {
    font-size: 1.25em;
  }

  > .style2 {
    font-size: 2.25em;
  }
}
